<template>
  <b-card class="mb-1 pb-0">
    <b-row>
      <!-------------------------- Datetime picker Start --------------------------->
      <b-col lg="4">
        <b-form-datepicker
          :id="datePickerId"
          v-model="selectDate"
          class="mb-1"
          @input="selectedDatePicker"
        />
      </b-col>
      <!-------------------------- Datetime picker End ----------------------------->
      <!-------------------------- Day / Week switch buttons Start --------------------------->
      <b-col lg="8">
        <b-form-group class="float-right mb-1">
          <b-form-radio-group
            id="switch-day-week"
            v-model="selectedRadioDayWeek"
            name="radios-btn-default"
            button-variant="outline-primary"
            :options="optionsRadioDayWeek"
            buttons
            @change="selectedRadioDayWeekChange"
          />
        </b-form-group>
      </b-col>
      <!-------------------------- Day / Week switch buttons End ----------------------------->
    </b-row>
    <b-row class="mb-0">
      <b-col class="col-12 col-lg-4">
        <FilterCustomers
          :group-options="groups"
          :status-options="statuses"
          :group-filter="group"
          :status-filter="status"
          @updateStatus="handleStatus"
          @updateGroup="handleGroup"
        />
      </b-col>
      <b-col class="col-12 col-lg-6 ml-auto">
        <div>
          <!-------------------------- Search box Start -------------------------->
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query"
              :placeholder="$t('search') + '...'"
              @change="handleQuery"
            />
          </b-input-group>
          <!-------------------------- Search box End ---------------------------->
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-add-week"
      :title="$t('add note')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      :ok-title="$t('save')"
      cancel-variant="outline-secondary"
    >
      <b-form-group
        :label="$t('date')"
        label-for="note-date"
      >
        <loan-date-picker
          id="note-date"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', }"
        />
      </b-form-group>
      <b-form-group
        label="Note"
        label-for="note-edit"
      >
        <b-form-textarea
          id="note-edit"
          rows="4"
          placeholder="Content"
        />
      </b-form-group>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormGroup, BModal, BFormRadioGroup, BFormTextarea, VBModal, BFormDatepicker, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LoanDatePicker from '@/views/components/elements/LoanDatePicker.vue'
import FilterCustomers from '@/views/components/FilterCustomers.vue'
import { required, positive } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    LoanDatePicker,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BModal,
    BFormRadioGroup,
    BFormTextarea,
    FilterCustomers,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    statuses: {
      type: Array,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    groups: {
      type: Array,
      default: null,
    },
    group: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      positive,
      query: '',
      selectDate: this.$moment().format('YYYY-MM-DD'),
      selectedRadioDayWeek: 'day',
      optionsRadioDayWeek: [
        { text: this.$t('day'), value: 'day' },
        { text: this.$t('week'), value: 'week' },
      ],
      datePickerId: 'dashboard-daily-date-picker',
    }
  },
  methods: {
    handleStatus(key) {
      this.$emit('updateStatus', key)
    },
    handleGroup(key) {
      this.$emit('updateGroup', key)
    },
    handleQuery() {
      this.$emit('updateQuery', this.query)
    },
    selectedRadioDayWeekChange(e) {
      this.$emit('switchDayWeek', e === 'week')
    },
    selectedDatePicker(e) {
      this.$emit('selectDate', e)
    },
  },
}
</script>
