<template>
  <div class="w-100 overflow-auto scroll-touch d-flex week-box-view">
    <div
      v-for="(day, index) in weekDates"
      :key="day"
      class="day-box"
    >
      <div class="day-box-header border-bottom p-50 text-center">
        {{ '(' + weekLang(day) + ')' }} {{ day | moment('MM/DD') }}
      </div>
      <div
        class="day-box-view-content p-1"
        :class="{ 'border-right': (index < weekDates.length - 1)}"
      >
        <!-- <log-note-box :data="day" /> -->
        <log-user-box
          v-for="customer in dailyDueDateCustomers[day]"
          :key="customer.cid"
          :data="customer"
          :statuses="statuses"
          :status-filter="filterStatus"
          :group-filter="filterGroup"
          @customerInfoModal="handleCustomerInfoModal"
        />
      </div>
    </div>

    <b-modal
      v-if="modalCustomerInfo"
      id="customer-info-modal"
      :title="$t('customer information')"
      :hide-header-close="true"
      :hide-footer="true"
      size="lg"
      body-class="py-2"
      centered
    >
      <b-row>
        <b-col sm="4">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside
              class="mr-0"
            >
              <label for="fileInput">
                <b-avatar
                  :src="modalCustomerInfo ? modalCustomerInfo.photoURL : ''"
                  size="5.5rem"
                />
              </label>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-weight-bolder text-primary text-nowrap mb-0 ml-1">
                {{ modalCustomerInfo.basicInfo ? modalCustomerInfo.basicInfo.fullName : '' }}
              </b-card-text>
              <b-card-text class="font-small-3 mb-0 ml-1">
                {{ modalCustomerInfo ? modalCustomerInfo.group : '' }}
              </b-card-text>
              <b-dropdown
                v-if="modalCustomerInfo && modalCustomerInfo.status"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="$t(statuses.find(d => d.key === modalCustomerInfo.status).name)"
                :variant="`flat-${statuses.find(d => d.key === modalCustomerInfo.status).type}`"
                size="sm"
                style="width: 100px;"
              >
                <b-dropdown-item
                  v-for="item in statuses"
                  :key="item.key"
                  :active="item.key === modalCustomerInfo.status"
                  @click="changeStatus(item.key)"
                >
                  {{ $t(item.name) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-media-body>
          </b-media>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
          >
            {{ $t('save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="$bvModal.hide('customer-info-modal')"
          >
            {{ $t('cancel') }}
          </b-button> -->
        </b-col>
        <b-col sm="4">
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('principal') }}</label>
            <label class="font-weight-light">{{ modalCustomerInfo.loan ? numberWithCommas(modalCustomerInfo.loan.principal) : '' }}</label>
          </div>
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('payable') }}</label>
            <label class="font-weight-light">{{ modalCustomerInfo.loan ? numberWithCommas(modalCustomerInfo.loan.payable) : '' }}</label>
          </div>
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('paid') }}</label>
            <label
              v-if="modalCustomerInfo.paid && $can('create', 'transactions')"
              class="font-weight-light cursor-pointer"
            >
              {{ modalCustomerInfo.paid }}
            </label>
            <label
              v-else-if="!modalCustomerInfo.paid && $can('create', 'transactions')"
              v-b-modal.modal-paid
              class="font-weight-light cursor-pointer"
            >
              <u>{{ '-' }}</u>
            </label>
          </div>
          <!-- <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('allowance') }}</label>
            <label class="font-weight-light">{{ '-' }}</label>
          </div>
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('penalty') }}</label>
            <label class="text-danger">{{ '$1950' }}</label>
          </div> -->
        </b-col>
        <b-col sm="4">
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('due date') }}</label>
            <label class="font-weight-light">{{ modalCustomerInfo.loan.dueDateTime | moment('YYYY/M/DD HH:mm') }}</label>
          </div>
          <div class="mb-50">
            <label class="font-weight-bold w-50">{{ $t('note') }}</label>
            <label
              v-b-modal.customer-note-edit-modal
              class="font-weight-light cursor-pointer"
            >
              <u>{{ modalCustomerInfo.debtInfo.note }}</u>
            </label>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-paid"
      :title="$t('edit')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok="$emit('payInterest');changeField('paid', modalPaid.paid)"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              :label="$t('date')"
              label-for="add-date"
            >
              <b-form-datepicker
                id="add-date"
                v-model="modalPaid.date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('time')"
              label-for="add-time"
            >
              <b-form-timepicker
                id="add-time"
                v-model="modalPaid.time"
                locale="en"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('amount')"
              label-for="paid-amount"
            >
              <b-input-group
                append=".00"
              >
                <b-form-input
                  id="paid-amount"
                  v-model="modalPaid.paid"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="customer-note-edit-modal"
      :title="$t('edit')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      cancel-variant="outline-primary"
      centered
      @ok="$emit('updateNote');changeField('note', modalNote.note)"
    >
      <b-form-group
        :label="$t('note')"
        label-for="note-edit"
      >
        <b-form-textarea
          id="note-edit"
          v-model="modalNote.note"
          rows="4"
          :placeholder="$t('content')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BCardText, BDropdown, BDropdownItem, /* BButton,  */VBModal, BFormGroup, BInputGroup, BFormInput, BForm, BFormTextarea, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import LogNoteBox from './LogNoteBox.vue'
import LogUserBox from './LogUserBox.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormTimepicker,
    // BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    // LogNoteBox,
    LogUserBox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    statuses: {
      type: Array,
      default: null,
    },
    filterStatus: {
      type: String,
      default: null,
    },
    filterGroup: {
      type: String,
      default: null,
    },
    weekDates: {
      required: true,
      type: Array,
      default: null,
    },
    dailyDueDateCustomers: {
      required: true,
      type: Object,
      default: null,
    },
    modalNote: {
      type: Object,
      default: () => {},
    },
    modalPaid: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalCustomerInfo: null,
      modalCustomerStatus: null,
      imgFile: null,
    }
  },
  computed: {
    filteredDueDate() {
      const weeklyData = {}
      this.weekDates.forEach(date => {
        const customers = this.dailyDueDateCustomers[date] || []
        if (this.filterGroup && this.filterStatus) {
          weeklyData[date] = customers.filter(customer => customer.group === this.filterGroup && customer.status === this.filterStatus)
        }
        if (this.filterGroup) {
          weeklyData[date] = customers.filter(customer => customer.group === this.filterGroup)
        }
        if (this.filterStatus) {
          weeklyData[date] = customers.filter(customer => customer.status === this.filterStatus)
        }
      })
      return weeklyData
    },
  },
  methods: {

    weekLang(day) {
      const weekDay = this.$moment(day).format('ddd')
      return this.$t(weekDay)
    },
    handleCustomerInfoModal(customer, status) {
      this.$emit('handleNote', customer)
      this.$emit('handlePaid', customer)
      this.$set(this, 'modalCustomerInfo', { ...customer })
      this.$set(this, 'modalCustomerStatus', status)
      this.$bvModal.show('customer-info-modal')
    },
    changeField(key, value) {
      if (key === 'note') {
        this.modalCustomerInfo.debtInfo.note = value
      } else if (key === 'paid') {
        this.modalCustomerInfo.paid = value
      }
    },
    changeStatus(key) {
      this.$set(this, 'modalCustomerInfo', { // Update the state(modalCustomerInfo.status) for status(observed, processing, closed, bad debt) of a customer
        ...this.modalCustomerInfo,
        status: key,
      })
    },
  },
}
</script>

<style lang="scss">
.day-box {
  width: calc(100%/7);
  min-width: 180px;
}
.day-box-view-content {
  height: calc(100% - 36px);
}
.week-box-view {
  min-height: 600px;
}
</style>
