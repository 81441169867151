<template>
  <b-card
    v-if="(!groupFilter && !statusFilter) ||
      (!statusFilter && groupFilter && data.group === groupFilter) ||
      (!groupFilter && statusFilter && data.status === statusFilter) ||
      (data.group === groupFilter && data.status === statusFilter)"
    class="loan-box px-75 py-1 mb-1"
    no-body
  >
    <div class="w-100 d-flex justify-content-between mb-1">
      <b-badge
        class="circle-rounded px-1 font-weight-normal"
        :variant="`light-${status.type}`"
      >
        {{ $t(data.status) }}
      </b-badge>
    </div>
    <div
      class="d-flex align-items-center cursor-pointer user-card"
      @click="clickCustomerBox"
    >
      <div class="pr-50">
        <b-avatar
          :src="data.photoURL"
        />
      </div>
      <div>
        <h6 class="font-weight-bolder mb-0 text-gray text-nowrap">
          {{ data.basicInfo.fullName }}
        </h6>
        <small class="m-0">{{ data.group + " " + data.basicInfo.accountNumber }}</small><br>
      </div>
    </div>
    <div>
      <small class="m-0">{{ $t('payable') + ': ' }}{{ '$' + numberWithCommas(data.loan.payable) }}</small>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BBadge, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    statuses: {
      type: Array,
      default: null,
    },
    groupFilter: {
      type: String,
      default: null,
    },
    statusFilter: {
      type: String,
      default: null,
    },
  },
  computed: {
    status() {
      return this.statuses.find(d => d.key === this.data.status)
    },
  },
  methods: {
    clickCustomerBox() {
      this.$emit('customerInfoModal', this.data, this.status)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/base/colors.scss';
.user-card:hover {
  .user-name {
    color: $primary;
  }
}
</style>
