<template>
  <b-card
    no-body
    class="px-2 py-1 mb-0 w-100"
  >
    <b-row>
      <b-col lg="6">
        <div class="h-100 d-flex flex-column justify-content-around">
          <PriceView
            :label="data.amount.label"
            :value="data.amount.value"
            pre-string="$"
            size="large"
          />
          <PriceView
            :label="data.received.label"
            :value="data.received.value"
            pre-string="$"
            size="small"
          />
        </div>
      </b-col>
      <b-col lg="6">
        <vue-apex-charts
          height="110"
          :options="chartInfo.options"
          :series="circuleData"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { subtract } from 'mathjs'
import { $themeColors } from '@themeConfig'
import PriceView from './PriceView.vue'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    PriceView,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartInfo: {
        options: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: [this.$t('should receive'), this.$t('received')],
          stroke: { width: 0 },
          colors: [$themeColors.success, $earningsStrokeColor2, $earningsStrokeColor3],
          grid: {
            padding: {
              right: 0,
              bottom: 0,
              top: 0,
              left: 0,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                    fontSize: '11px',
                    color: '#D0D2D6',
                  },
                  value: {
                    offsetY: -15,
                    fontSize: '20px',
                    color: '#D0D2D6',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return parseInt(val)
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: this.$t('should receive'),
                    fontSize: '11px',
                    color: '#D0D2D6',
                    formatter: () => (
                      this.numberWithCommas(this.data.amount.value)
                    ),
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
    circuleData() {
      return [subtract(this.data.amount.value, this.data.received.value), this.data.received.value]
    },
  },
}
</script>
