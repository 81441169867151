<template>
  <b-card no-body>
    <b-card-header>
      <div class="text-nowrap">
        <feather-icon
          size="22"
          class="mr-1"
          icon="ListIcon"
        />
        <span style="font-size: 14.98px;">
          {{ $t('messages and expenses') }}
        </span>
      </div>
      <div>
        <b-button
          v-if="$can('create', 'dashboard')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-add-day
          variant="primary"
          class="btn-icon"
          size="sm"
        >
          <feather-icon
            icon="PlusIcon"
            size="14"
          />
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <div
        class="h-100 overflow-auto"
        style="padding-top: 0px;"
      >
        <app-timeline class="pr-1">
          <app-timeline-item
            v-for="(item, index) in data"
            :key="index"
            :variant="item.type === 'note' ? 'warning' : 'danger'"
            :icon="typeIcons[item.type]"
            @contextmenu.prevent="$refs.menu.open"
            @contextmenu.native="setNoteOrExpense(item)"
          >
            <b-row class="mb-0">
              <b-col cols="7">
                <span
                  style="font-size: 13px;"
                >
                  {{ item.note }}
                </span>
              </b-col>
              <b-col
                cols="2"
                class="text-right"
              >
                <span
                  class="text-muted"
                  style="font-size: 11px;"
                >
                  {{ item.createdAt | moment('HH:mm') }}
                </span>
              </b-col>
              <b-col cols="3">
                <h6
                  v-if="!item.paid"
                  class="timeline-item-time text-nowrap text-right"
                  style="width: 70px; font-size: 13px;"
                >
                  {{ '-' }}
                </h6>
                <h6
                  v-else
                  class="timeline-item-time text-nowrap text-right"
                  style="width: 70px; font-size: 13px;"
                >
                  {{ '$' + numberWithCommas(item.paid) }}
                </h6>
              </b-col>
            </b-row>
            <b-row
              class="mt-0 pt-0"
            >
              <b-col
                cols="4"
              >
                <span
                  class="text-muted"
                  style="font-size: 12px;"
                >
                  {{ item.userName }}
                </span>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
      </div>
    </b-card-body>
    <vue-context ref="menu">
      <li>
        <b-link
          class="d-flex align-items-center"
          @click="deleteNoteExpense(neData)"
        >
          <feather-icon
            icon="XCircleIcon"
            size="16"
          />
          <span class="ml-75">{{ $t('delete') }}</span>
        </b-link>
      </li>
    </vue-context>
    <validation-observer
      ref="add-modal"
      v-slot="{ invalid }"
    >
      <b-modal
        id="modal-add-day"
        :title="$t('add')"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :ok-title="$t('submit')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-secondary"
        footer-class="justify-content-end flex-row-reverse"
        :ok-disabled="invalid"
        @ok="addSubmit"
        @cancel="defaultValue"
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('type')"
                label-for="add-type"
              >
                <b-dropdown
                  id="add-type"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mb-1 full-width-dropdown"
                  variant="outline-primary"
                  block
                  :text="$t(incometypes.find(d => d.key === addInfo.type).name)"
                >
                  <b-dropdown-item
                    v-for="item in incometypes"
                    :key="item.key"
                    @click="handleIncomeType(item.key)"
                  >
                    {{ $t(item.name) }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="['interest', 'penalty'].includes(addInfo.type)">
            <b-col>
              <b-form-group
                :label="$t('customer')"
                label-for="add-name"
              >
                <b-dropdown
                  id="add-name"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mb-1 full-width-dropdown"
                  variant="outline-primary"
                  block
                >
                  <template v-slot:button-content>
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :src="addInfo.user.avatar"
                        size="sm"
                        class="mr-50"
                      />
                      <span>{{ addInfo.user.name }}</span>
                    </div>
                  </template>
                  <b-dropdown-item
                    v-for="item in users"
                    :key="item.id"
                    @click="handleIncomeUser(item.id)"
                  >
                    <div class="d-flex align-items-center">
                      <b-avatar
                        :src="item.avatar"
                        class="mr-50"
                      />
                      <div>
                        <span>{{ item.name }}</span><br>
                        <small class="m-0">{{ item.category + " " + item.uid }}</small>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="addInfo.type !== 'note'"
              cols="6"
            >
              <b-form-group
                :label="$t('date')"
                label-for="add-date"
              >
                <b-form-datepicker
                  id="add-date"
                  v-model="addInfo.date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="addInfo.type !== 'note'"
              cols="6"
            >
              <b-form-group
                :label="$t('time')"
                label-for="add-time"
              >
                <b-form-timepicker
                  id="add-time"
                  v-model="addInfo.time"
                  locale="en"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                :name="$t('amount')"
                rules="positive"
              >
                <b-form-group
                  v-if="addInfo.type !== 'note'"
                  :label="$t('amount')"
                  label-for="add-amount"
                >
                  <b-input-group
                    :prepend="addInfo.currency"
                    append=".00"
                  >

                    <b-form-input
                      id="add-amount"
                      v-model="addInfo.amount"
                    />

                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                v-if="['note', 'expense'].includes(addInfo.type)"
                :label="$t('note')"
                label-for="add-note"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="addInfo.note"
                  :placeholder="$t('note')"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, VBTooltip, BRow, BCol, BButton, VBModal, BDropdown, BDropdownItem, BForm, BFormTimepicker, BInputGroup, BAvatar, BFormGroup, BFormDatepicker, BFormInput, BFormTextarea, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VueContext from 'vue-context'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, positive } from '@validations'
import store from '@/store'

localize('tw')

export default {
  components: {
    BLink,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTimepicker,
    BInputGroup,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    VueContext,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    incometypes: {
      type: Array,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    selectDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      positive,
      typeIcons: {
        note: 'EditIcon',
        expense: 'DollarSignIcon',
      },
      incomeType: this.incometypes[0].key,
      addInfo: {
        type: this.incometypes[0].key || '',
        user: this.users[0] || '',
        note: null,
        date: this.selectDate,
        time: this.$moment().format('HH:mm'),
        amount: null,
        currency: '$',
      },
      neData: {},
    }
  },
  watch: {
    selectDate(newValue) {
      this.addInfo.date = newValue
    },
  },
  methods: {
    defaultValue() {
      this.addInfo = {
        type: this.incometypes[0].key || '',
        user: this.users[0] || '',
        note: null,
        date: this.selectDate,
        time: this.$moment().format('HH:mm'),
        amount: null,
        currency: '$',
      }
    },
    addSubmit() {
      if (this.addInfo.type === 'note') {
        this.addInfo.time = this.$moment().format('HH:mm:ss')
        const notes = {
          type: this.addInfo.type,
          note: this.addInfo.note,
          dateTime: this.$moment(`${this.addInfo.date} ${this.addInfo.time}`, 'YYYY-MM-DD HH:mm:ss').format(),
        }
        this.$emit('createActions', notes)
      } else if (this.addInfo.type === 'expense') {
        const expense = {
          type: this.addInfo.type,
          note: this.addInfo.note,
          paid: this.addInfo.amount,
          dateTime: this.$moment(`${this.addInfo.date} ${this.addInfo.time}`, 'YYYY-MM-DD HH:mm').format(),
        }
        this.$emit('createActions', expense)
      }
      this.defaultValue()
    },
    handleIncomeType(key) {
      this.$set(this, 'addInfo', { ...this.addInfo, type: key })
    },
    setNoteOrExpense(item) {
      this.$set(this, 'neData', item)
    },
    deleteNoteExpense(item) {
      this.$swal({
        title: `${this.$t('are you sure you want to delete')} ${item.note} ?`,
        text: this.$t('are you sure you want to delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yes, delete!'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (item.type === 'note') {
            store.dispatch('deleteNote', { ...item, data: item })
          } else if (item.type === 'expense' && this.$can('delete', 'transactions')) {
            store.dispatch('deleteTransaction', { ...item })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-context.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
