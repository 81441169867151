<template>
  <b-card class="mb-0">
    <div class="d-flex flex-column justify-content-between h-100">
      <!-- allowances -->
      <PriceView
        label="allowances"
        :value="data.allowances"
        pre-string="$"
        size="large"
      />
      <!-- penalties -->
      <PriceView
        label="penalties"
        :value="data.penalties"
        pre-string="$"
        size="large"
      />
      <!-- expenses -->
      <PriceView
        label="expenses"
        :value="data.expenses"
        pre-string="$"
        size="large"
      />
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import PriceView from './PriceView.vue'

export default {
  components: {
    BCard,
    PriceView,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>
