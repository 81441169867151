import { render, staticRenderFns } from "./LogUserBox.vue?vue&type=template&id=2a633faa&scoped=true&"
import script from "./LogUserBox.vue?vue&type=script&lang=js&"
export * from "./LogUserBox.vue?vue&type=script&lang=js&"
import style0 from "./LogUserBox.vue?vue&type=style&index=0&id=2a633faa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a633faa",
  null
  
)

export default component.exports