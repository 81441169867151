<template>
  <b-row>
    <b-col
      cols="6"
      class="mb-md-0 mb-2"
    >
      <v-select
        :value="groupFilter"
        :options="groupOptions"
        :reduce="val => val.value"
        @input="(val) => $emit('updateGroup', val)"
      />
    </b-col>
    <b-col
      cols="6"
      class="mb-md-0 mb-2"
    >
      <v-select
        :value="statusFilter"
        :options="statusOptions"
        :reduce="val => val.value"
        @input="(val) => $emit('updateStatus', val)"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    groupFilter: {
      type: String,
      default: null,
    },
    statusFilter: {
      type: String,
      default: null,
    },
    groupOptions: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
