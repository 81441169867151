<template>
  <div class="gross-view">
    <p
      class="gross-label m-0"
      :style="{ fontSize: size === 'small' ? '10px' : '12px', lineHeight: size === 'small' ? '14px' : '16px' }"
    >
      {{ $t(label) }}
    </p>
    <p
      class="gross-value m-0"
      :style="{ fontSize: size === 'small' ? '14px' : '20px', lineHeight: size === 'small' ? '18px' : '24px' }"
    >
      {{ preString + numberWithCommas(value) }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    preString: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
  },
}
</script>
